export const COUNTRY_SPECIFIC_FIELDS = [
  'organizationNumber', 'vatNumber', 'location', 'postalCode', 'address', 'candidatePortalLanguage', 'customerPortalLanguage'
];
export const ALLOWED_IMAGE_TYPES = ['png', 'jpg', 'jpeg'];
export const SMS_TITLE_LENGTH = 11;

export enum WriteMode {
  create = 'create',
  edit = 'edit'
}

export enum PostalCodeLength {
  swe = 5,
  nor = 4,
  usa = 5,
  dan = 4,
  fin = 5,
  ger = 5,
}

export enum Platforms {
  facebookAndInstagram = 1,
}

export type Platform = 'facebookAndInstagramCompany';

export const PUBLISHING_PLATFORMS: Map<Platforms, Platform> = new Map([
  [
    Platforms.facebookAndInstagram,
    'facebookAndInstagramCompany'
  ]
]);

export const MAX_CANDIDATE_DATA_LIFE_TIME = setMaxCandidateDataLifetime();

function isLeapYear(year: number): boolean {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

function setMaxCandidateDataLifetime(): number {
  const date = new Date();
  const currentYear = date.getFullYear();
  const nextYear = currentYear + 1;
  let dataLifetime = isLeapYear(currentYear) ? 366 : 365;
  dataLifetime += isLeapYear(nextYear) ? 366 : 365;

  return dataLifetime;
}
