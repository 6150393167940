import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Company,
  CountryLocation,
  Country,
  TranslationKeys
} from '../model/company.interface';
import { Observable, tap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PublishingPlatform } from '../model/publishing-platform.interface';
import { UserBranch } from '../model/user-branch.interface';
import { PUBLISHING_PLATFORMS } from '../resources/company.constants';
import { Pagination } from '../model/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  private _currentCompany!: Company | null;
  private _currentCountry!: Country | null;
  private _currentCountryId!: number;

  constructor(private http: HttpClient) {}

  get currentCompany(): Company {
    return this._currentCompany as Company;
  }

  get currentCompanyId(): number {
    return this.currentCompany.id;
  }

  get currentCompanyUniversalJobId(): number {
    return this.currentCompany.universalJob?.id;
  }

  get currentCompanyGuid(): string {
    return this.currentCompany.guid;
  }

  get currentCountryId(): number {
    return this._currentCountryId;
  }

  set currentCountryId(id: number) {
    this._currentCountryId = id;
  }

  get currentCompanyLanguage(): TranslationKeys {
    return this.currentCompany.language;
  }

  get currentCountry(): Country {
    return this._currentCountry as Country;
  }

  resetCompany(): void {
    this._currentCompany = null;
    this._currentCountry = null;
    this.currentCountryId = 0;
  }

  getCompany(companyId: number): Observable<Company> {
    return this.http
      .get<Pagination<Company>>(`${environment.companies}/${companyId}`)
      .pipe(
        map(({data}: Pagination<Company>) => data[0]),
        tap((company: Company) => {
          company.jobPlatforms = (company.jobPlatforms as PublishingPlatform[])
            .map(platform => platform.id);
          this._currentCompany = company;
          this._currentCountry = company.location.country;

          this._currentCountryId = this._currentCountry.id;

        })
      );
  }

  getCompanies(haveEnterprise: boolean = true): Observable<Company[]> {
    let params = new HttpParams();
    params = params.append('limit', '1000');

    if (!haveEnterprise) {
      params = params.append('haveEnterprises', false.toString());
    }
    return this.http
      .get<Pagination<Company>>(environment.companies, { params })
      .pipe(
        map(({data}: Pagination<Company>) => data)
      );
  }

  createCompany(company: Company): Observable<Company> {
    company.companyLogoEnc = company.companyLogo;

    const jobPlatforms = company.jobPlatforms as number[];
    this.removeJobPlatforms(jobPlatforms, company);

    delete company.companyLogo;
    delete company.country;
    delete company.apiToken;

    return this.http.post<Pagination<Company>>(`${environment.companies}`, company)
      .pipe(
        map(({data}: Pagination<Company>) => data[0]),
        tap((newCompany) => this._currentCompany = newCompany)
      );
  }

  updateCompany(company: Company): Observable<Company> {
    if (company.companyLogo) {
      company.companyLogoEnc = company.companyLogo;
    }

    const jobPlatforms = company.jobPlatforms as number[];

    this.removeJobPlatforms(jobPlatforms, company);

    delete company.companyLogo;
    delete company.municipality;
    delete company.country;

    return this.http
      .put<Company>(
        `${environment.companies}/${this.currentCompanyId}`,
        company
      );
  }

  removeJobPlatforms(jobPlatforms: number[], company: Company): void {
    PUBLISHING_PLATFORMS
      .forEach((platform, id) => {
        if (jobPlatforms.indexOf(id) < 0) {
          company[platform] = null;
        }
      });
  }

  getBranches(): Observable<UserBranch[]> {
    return this.http
      .get<Pagination<UserBranch>>(
        `${environment.companies}/${this.currentCompanyGuid}/branches?limit=1000&company=${this.currentCompanyId}`
      )
      .pipe(
        map(({data}: Pagination<UserBranch>) => data)
      );
  }

  getLocations(): Observable<CountryLocation[]> {
    const params: HttpParams = new HttpParams().set('country', this.currentCountryId.toString());

    return this.http
      .get<Pagination<CountryLocation>>(`${environment.locations}?limit=1000`, { params })
      .pipe(
        map(({data}: Pagination<CountryLocation>) => data)
      );
  }

  getCountries(): Observable<Country[]> {
    let params = new HttpParams();
    params = params.append('limit', '1000');

    return this.http
      .get<Pagination<Country>>(`${environment.countries}`, { params })
      .pipe(
        map(({data}: Pagination<Country>) => data)
      );
  }

  getCountryById(): Observable<Country> {
    return this.http.get<Country>(`${environment.countries}/${this.currentCountryId}`);
  }

  getCountryLocations(): Observable<CountryLocation[]> {
    let params = new HttpParams();
    params = params.append('limit', '10000');

    return this.http
      .get<Pagination<CountryLocation>>(
        `${environment.countries}/${this.currentCountryId}/locations`,
        { params }
      )
      .pipe(
        map(({data}: Pagination<CountryLocation>) => data)
      );
  }

  createBranch(branch: UserBranch): Observable<UserBranch[]> {
    return this.http
      .post<Pagination<UserBranch>>(`${environment.branches}`, branch)
      .pipe(
        map(({data}: Pagination<UserBranch>) => data)
      );
  }

  getPublishingPlatforms(): Observable<PublishingPlatform[]> {
    const params = new HttpParams().set('country', this.currentCountryId.toString());

    return this.http
      .get<PublishingPlatform[]>(
        environment.platforms,
        {params}
      );
  }

  regenerateApiKey(): Observable<Company> {
    return this.http
      .get<Pagination<Company>>(
        `${environment.companies}/${this.currentCompanyId}/regenerate_api_token`
      )
      .pipe(
        map(({data}: Pagination<Company>) => data[0])
      );
  }

}
